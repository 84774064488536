import React, { Component } from "react"
import { Transition } from "react-transition-group"
import { Link, graphql } from "gatsby"

import { rhythm } from "../utils/typography"

import logo from "../images/iCertify Logo.png"

class Intro extends Component {
  state = {
    index: 0,
    showLogo: false,
    screenSize: undefined,
    showButton1: false,
    showButton2: false,
    showButton3: false,
    showButton4: false,
  }
  buttonPressTimer
  duration = 1000
  defaultStyle = {
    transition: `opacity ${this.duration}ms ease-in-out`,
    opacity: 0,
    visibility: "hidden",
  }

  transitionStyles = {
    entering: { opacity: 1, visibility: "visible" },
    entered: { opacity: 1, visibility: "visible" },
    exiting: { opacity: 0, visibility: "hidden" },
    exited: { opacity: 0, visibility: "hidden" },
  }
  // cursorRef
  // linkRefs
  // pulse = "pulse"
  // inactive = "inactive"

  constructor(props) {
    super(props)
    this.handleButtonPress = this.handleButtonPress.bind(this)
    this.handleButtonRelease = this.handleButtonRelease.bind(this)
  }

  componentDidMount() {
    // this.cursorRef = document.querySelector(".cursor") as HTMLElement
    // this.linkRefs = document.querySelectorAll("a")
    // document.addEventListener("mousemove", this.moveMouse)
    // this.linkRefs.forEach(link =>
    //   link.addEventListener("mouseover", this.disableAnimation)
    // )
    // this.linkRefs.forEach(link =>
    //   link.addEventListener("mouseleave", this.disableAnimation)
    // )
    this.setState({ screenSize: window.innerWidth })
    window.addEventListener("resize", () => {
      this.setState({
        screenSize: window.innerWidth,
      })
    })
    let buttonTimeout1 = setTimeout(() => {
      console.log("Show Button 1")
      clearTimeout(buttonTimeout1)
      this.setState({ showButton1: true })
    }, 1500)
  }

  handleButtonPress() {
    this.buttonPressTimer = setTimeout(() => {
      console.log("Long Pressed...")
      this.proceedHandler()
    }, 800)
  }

  handleButtonRelease() {
    console.log("Clicked...")
    clearTimeout(this.buttonPressTimer)
  }

  proceedHandler = () => {
    this.setState({ index: this.state.index + 1 }, () => {
      switch (this.state.index) {
        case 1:
          let buttonTimeout2 = setTimeout(() => {
            console.log("Show Button 2")
            clearTimeout(buttonTimeout2)
            this.setState({ showButton2: true })
          }, 2500)
          break
        case 2:
          if (this.state.screenSize < 736) {
            let buttonTimeout3 = setTimeout(() => {
              console.log("Show Button 3")
              clearTimeout(buttonTimeout3)
              this.setState({ showButton3: true })
            }, 2500)
          } else {
            let cursorTimeout = setTimeout(() => {
              console.log("Change Cursor")
              clearTimeout(cursorTimeout)
              let thirdPassage = document.querySelector(
                ".third-passage"
              ) as HTMLElement
              thirdPassage.classList.add("custom-cursor")
            }, 2000)
          }
          break
        case 3:
          let buttonTimeout4 = setTimeout(() => {
            console.log("Show Button 4")
            clearTimeout(buttonTimeout4)
            this.setState({ showButton4: true })
          }, 2000)
          break
      }
    })
  }

  handleLogo = () => {
    this.setState({ showLogo: true })
  }

  handleClose = () => {
    this.props.onClose()
  }

  // moveMouse = e => {
  //   const x = e.clientX
  //   const y = e.clientY
  //   this.cursorRef.style.transform = `translate(${x - 15})px, ${y - 15}px`
  // }

  // disableAnimation = () => {
  //   const hasPulseClass = this.cursorRef.classList.contains(this.pulse)

  //   if (hasPulseClass) {
  //     this.cursorRef.classList.add(this.inactive)
  //     this.cursorRef.classList.remove(this.pulse)
  //   } else {
  //     this.cursorRef.classList.add(this.pulse)
  //     this.cursorRef.classList.remove(this.inactive)
  //   }
  // }

  renderArea = () => {
    switch (this.state.index) {
      case 0:
        return (
          <div className="passage first-passage">
            <h1>The Digital Notary</h1>

            <Transition in={this.state.showButton1} timeout={this.duration}>
              {state => (
                <button
                  className="intro-button"
                  style={{
                    ...this.defaultStyle,
                    ...this.transitionStyles[state],
                  }}
                  onClick={this.proceedHandler}
                >
                  Enter to Experience
                </button>
              )}
            </Transition>
          </div>
        )
      case 1:
        return (
          <div className="passage second-passage">
            <div className="animated-title">
              <div className="text-top">
                <div>
                  <span>The pre-pandemic and remote notarization</span>
                  <span>has caused a bottleneck of legal document</span>
                </div>
              </div>
              <div className="text-bottom">
                <div>transactions in the country.</div>
              </div>
            </div>

            <Transition in={this.state.showButton2} timeout={this.duration}>
              {state => (
                <button
                  className="intro-button second-button"
                  onClick={this.proceedHandler}
                  style={{
                    ...this.defaultStyle,
                    ...this.transitionStyles[state],
                  }}
                >
                  Next
                </button>
              )}
            </Transition>
          </div>
        )
      case 2:
        return (
          <div className="passage third-passage">
            <div className="animated-title">
              <div className="text-top2">
                <div>
                  <span className="text-span01">This</span>
                  <span className="text-span02">is where</span>
                </div>
              </div>
              <div className="text-bottom2">
                <div>we come in.</div>
              </div>
            </div>
            {this.state.screenSize < 736 ? (
              <Transition in={this.state.showButton3} timeout={this.duration}>
                {state => (
                  <button
                    className="intro-button third-button"
                    onClick={this.proceedHandler}
                    style={{
                      ...this.defaultStyle,
                      ...this.transitionStyles[state],
                    }}
                  >
                    Next
                  </button>
                )}
              </Transition>
            ) : null}
          </div>
        )
      case 3:
        return (
          <Transition in={this.state.showLogo} timeout={this.duration}>
            {state => (
              <div>
                <div
                  className="image-wrapper main-logo-wrapper"
                  style={{
                    ...this.defaultStyle,
                    ...this.transitionStyles[state],
                  }}
                >
                  <img
                    src={logo}
                    className="main-logo"
                    alt="Logo"
                    onLoad={this.handleLogo}
                  />
                </div>
                <Transition in={this.state.showButton4} timeout={this.duration}>
                  {state => (
                    <button
                      className="intro-button fourth-button"
                      onClick={this.handleClose}
                      style={{
                        ...this.defaultStyle,
                        ...this.transitionStyles[state],
                      }}
                    >
                      Enter
                    </button>
                  )}
                </Transition>
              </div>
            )}
          </Transition>
        )
    }
  }

  render() {
    console.log(this.state.screenSize)
    return (
      <div className="container" id="intro-container">
        <div className="overlay"></div>
        <div className="sliding-background"></div>
        <div className="sliding-background sliding-background-1"></div>
        <div className="sliding-background sliding-background-2"></div>
        <div className="sliding-background sliding-background-3"></div>
        <div className="sliding-background sliding-background-4"></div>
        {/* <div className="cursor pulse"></div> */}

        <section
          id="intro"
          className="intro-wrapper"
          onTouchStart={this.handleButtonPress}
          onTouchEnd={this.handleButtonRelease}
          onMouseDown={this.handleButtonPress}
          onMouseUp={this.handleButtonRelease}
        >
          {this.renderArea()}
        </section>
      </div>
    )
  }
}

export default Intro
