import React from "react"

import ContactForm from "./contactForm"
import ContactInformation from "./contactInformation"

const Contact = ({ screenSize }) => {
  return (
    <section id="contact" className="wrapper fade-up contactSection">
      <div className="contact-header">
        <div className="overlay">
          <div className="merchant-wrapper">
            <div className="inner-header">
              <h2>Want to be part of our MERCHANT MATRIX?</h2>
              <p className="sectionSubheader">
                iCertify aims to empower thousands of notarial businesses in the
                country, be part of our matrix by simply sending us your online
                application.
              </p>
            </div>
            <div className="inner-form">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      <div className="inner">
        <h2>Contact Us</h2>
        <ContactInformation screenSize={screenSize} />
      </div>
    </section>
  )
}

export default Contact
