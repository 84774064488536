import React from "react"
class SwirlSVG extends React.Component {
  render() {
    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 740.45 857"
      >
        <path
          className="swirl1"
          d="M759.5,32.5a460.3,460.3,0,0,0-140-5c-55.29,6.51-94.74,21.37-125,33-89,34.2-128.58,73.36-138,83-26.79,27.43-33.45,45.29-36,59-6.64,35.74,10.88,65.35,16,74,15.92,26.91,38.33,41.44,59,53,46.89,26.23,81.86,35.57,149,56,20.69,6.29-4.28-1.18,166,67,12.82,5.13,37.74,15.1,44,37,4.48,15.68-1.12,35.71-14,45-27.74,20-79.8-16.55-86-21-35.36-25.39-28.43-43.58-61-68-8.84-6.63-53.65-40.22-102-25-47.61,15-64.24,66.47-67,75-12,37-2.88,69.71,1,83,14.06,48.18,39,48.26,50,93,2.43,9.85,13.48,54.73-12,91-16.22,23.08-39.66,31.9-51,36-27.73,10-47.68,6.12-115-3C236,787.16,205,783,192.5,784.5c-48.57,5.76-85,28.57-108,43a339,339,0,0,0-65,53"
          transform="translate(-19.14 -23.85)"
        />
      </svg>
    )
  }
}

export default SwirlSVG
