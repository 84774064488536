import React from "react"

const ContactInformation = ({ screenSize }) => (
  <section className="contactInfoSection" id="contact-us">
    <div className="contact">
      <div className="info">
        <h3>Address</h3>
        <span>
          Unit 3R The Garden Walk
          <br />
          Felix Avenue, Barangay Sto. Domingo
          <br />
          Cainta, Rizal
        </span>
      </div>
      <div className="info">
        <h3>Email</h3>
        <a href="mailto:customerservice@icertify.ph">
          customerservice@icertify.ph
        </a>
      </div>
      <div className="info">
        <h3>Phone</h3>
        <a href="tel:09171520144">+63917 152 0144</a>
      </div>
    </div>
  </section>
)

export default ContactInformation
