import React, { useState } from "react"
import step1Image from "../images/Step 1.png"
import step2Image from "../images/Step 2.png"
import step25Image from "../images/Step 2.5.png"
import step3Image from "../images/Step 3.png"
import step35Image from "../images/Step 3.5v2.png"
import step4Image from "../images/Step 4.png"
import { Transition } from "react-transition-group"

const Steps = ({ scrollPos, screenSize }) => {
  const [showStep1, setState1] = useState(false)
  const [showStep2, setState2] = useState(false)
  const [showStep3, setState3] = useState(false)
  const [showStep4, setState4] = useState(false)
  const [showStep5, setState5] = useState(false)
  const [showStep6, setState6] = useState(false)
  const duration = 750
  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
    visibility: "hidden",
  }

  const transitionStyles = {
    entering: { opacity: 1, visibility: "visible" },
    entered: { opacity: 1, visibility: "visible" },
    exiting: { opacity: 0, visibility: "hidden" },
    exited: { opacity: 0, visibility: "hidden" },
  }

  if (screenSize <= 736 && scrollPos > 2700 && !showStep1) {
    setState1(true)
  } else if (screenSize <= 736 && scrollPos > 3000 && !showStep2) {
    setState2(true)
  } else if (screenSize <= 736 && scrollPos > 3300 && !showStep3) {
    setState3(true)
  } else if (screenSize <= 736 && scrollPos > 3600 && !showStep4) {
    setState4(true)
  } else if (screenSize <= 736 && scrollPos > 3800 && !showStep5) {
    setState5(true)
  } else if (screenSize <= 736 && scrollPos > 4000 && !showStep6) {
    setState6(true)
  } else if (screenSize > 736 && scrollPos > 1900 && !showStep1) {
    setState1(true)
  } else if (screenSize > 736 && scrollPos > 2000 && !showStep2) {
    setState2(true)
  } else if (screenSize > 736 && scrollPos > 2100 && !showStep3) {
    setState3(true)
  } else if (screenSize > 736 && scrollPos > 2200 && !showStep4) {
    setState4(true)
  } else if (screenSize > 736 && scrollPos > 2300 && !showStep5) {
    setState5(true)
  } else if (screenSize > 736 && scrollPos > 2400 && !showStep6) {
    setState6(true)
  }

  return (
    <section id="steps" className="wrapper fullscreen">
      <h2>How it Works</h2>
      <div className="steps-shell">
        {screenSize > 736 ? (
          <div className="image">
            <img src={step1Image} className="step1-image" />
            <i className="fas fa-arrow-down"></i>
            <img src={step2Image} className="step2-image" />
            <i className="fas fa-arrow-down"></i>
            <img src={step3Image} className="step3-image" />
            <i className="fas fa-arrow-down"></i>
            <img src={step4Image} className="step4-image" />
          </div>
        ) : null}

        <div className="content">
          {screenSize <= 736 ? (
            <div>
              <br />
              <br />
            </div>
          ) : null}
          <div className="inner">
            <Transition in={showStep1} timeout={duration}>
              {state => (
                <div
                  style={{
                    ...defaultStyle,
                    ...transitionStyles[state],
                  }}
                >
                  {screenSize <= 736 ? (
                    <div className="xs-img step1-img-wrapper">
                      <img src={step1Image} className="step1-image" />
                    </div>
                  ) : null}
                  <div className="step step1">
                    <div className="number">1</div>
                    <div className="step-details">
                      <p>
                        Register: sign-up with your email and mobile number, ID,
                        photo and signature plus your payment details. If you
                        are a business, indicate your company, DTI/SEC and
                        upload photo of company representative.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </Transition>

            <Transition in={showStep2} timeout={duration}>
              {state => (
                <div
                  style={{
                    ...defaultStyle,
                    ...transitionStyles[state],
                  }}
                >
                  {screenSize <= 736 ? (
                    <div className="xs-img step1-img-wrapper">
                      <img src={step2Image} className="step1-image" />
                    </div>
                  ) : null}

                  <div className="step step2">
                    <div className="number">2</div>
                    <div className="step-details">
                      <p>
                        Upload document and designate principal signatories and
                        witnesses.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </Transition>

            <Transition in={showStep3} timeout={duration}>
              {state => (
                <div
                  style={{
                    ...defaultStyle,
                    ...transitionStyles[state],
                  }}
                >
                  {screenSize <= 736 ? (
                    <div className="xs-img step2-img-wrapper">
                      <img src={step25Image} className="step2-image" />
                    </div>
                  ) : null}
                  <div className="step step3">
                    <div className="number">3</div>
                    <div className="step-details">
                      <p>Notary reviews documents and signatories.</p>
                    </div>
                  </div>
                </div>
              )}
            </Transition>

            <Transition in={showStep4} timeout={duration}>
              {state => (
                <div
                  style={{
                    ...defaultStyle,
                    ...transitionStyles[state],
                  }}
                >
                  {screenSize <= 736 ? (
                    <div className="xs-img step3-img-wrapper">
                      <img src={step3Image} className="step3-image" />
                    </div>
                  ) : null}
                  <div className="step step4">
                    <div className="number">4</div>
                    <div className="step-details">
                      <p>Video conference with signatories and witnesses.</p>
                    </div>
                  </div>
                </div>
              )}
            </Transition>

            <Transition in={showStep5} timeout={duration}>
              {state => (
                <div
                  style={{
                    ...defaultStyle,
                    ...transitionStyles[state],
                  }}
                >
                  {screenSize <= 736 ? (
                    <div className="xs-img step5-img-wrapper">
                      <img src={step35Image} className="step4-image" />
                    </div>
                  ) : null}
                  <div className="step step5">
                    <div className="number">5</div>
                    <div className="step-details">
                      <p>Checkout and accomplish payment.</p>
                    </div>
                  </div>
                </div>
              )}
            </Transition>

            <Transition in={showStep6} timeout={duration}>
              {state => (
                <div
                  style={{
                    ...defaultStyle,
                    ...transitionStyles[state],
                  }}
                >
                  {screenSize <= 736 ? (
                    <div className="xs-img step5-img-wrapper">
                      <img src={step4Image} className="step4-image" />
                    </div>
                  ) : null}
                  <div className="step step6">
                    <div className="number">6</div>
                    <div className="step-details">
                      <p>Get notarized document and receipts via email.</p>
                    </div>
                  </div>
                </div>
              )}
            </Transition>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Steps
