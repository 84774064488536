import React from "react"
// import Scroll from "./Scroll"
import SwirlSVG from "./swirl"
import logo from "../images/iCertify Logo.png"

const Home = () => {
  const goToContact = () => {
    window.location.href = "#contact-us"
  }
  return (
    <section id="home" className="wrapper fullscreen">
      <div className="header-logo-wrapper">
        <img src={logo} className="main-logo" alt="Logo" />
      </div>
      <div className="inner">
        <SwirlSVG />
        <div className="textArea">
          <h1>Conveniently notarize your documents anytime, anywhere.</h1>
          <p>Have your documents notarized online with just a few taps.</p>
          <ul className="actions">
            <li>
              {/* <Scroll type="id" element="contact"> */}
              {/* <a href="#contact"> */}
              <button className="button" onClick={goToContact}>
                NOTARIZE NOW
              </button>
              {/* </a> */}
              {/* </Scroll> */}
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Home
