import React from "react"
import Feature from "./feature"
import Swirl2SVG from "./swirl2"

const Features = ({ scrollPos, screenSize }) => {
  const FEATURES = [
    {
      heading: "Convenient",
      description:
        "Access anytime, anywhere. Get the legal support that you and your company need when you need it.",
      iconClass: "fas fa-cloud",
    },
    {
      heading: "Paperless",
      description:
        "Our digitalization process eliminates and greatly reduces the use of paper therefore, lets your business to have a clutter-free environment.",
      iconClass: "fas fa-laptop",
    },
    {
      heading: "Contactless",
      description:
        "Faster, more secure and effortless transaction from the notary to customer.",
      iconClass: "fas fa-handshake-slash",
    },
    {
      heading: "Seamless",
      description:
        "The integration allows you to notarize in realtime and remain updated on its status.",
      iconClass: "fas fa-clock",
    },
    {
      heading: "Efficient & Productive",
      description:
        "Easily & systematically store documents through our topnotch Archiving & Retrieval System.",
      iconClass: "fas fa-archive",
    },
    {
      heading: "Safe",
      description:
        "Our Fraud Solution offers ID authentication, recording of video session and full audit trail for more trustworthy online transactions.",
      iconClass: "fas fa-shield-alt",
    },
  ]
  const [features] = React.useState(FEATURES)
  console.log(scrollPos)
  if (
    (screenSize <= 736 && scrollPos > 2300) ||
    (screenSize > 736 && scrollPos > 1500)
  ) {
    let swirlRef = document.querySelector(".swirl2") as HTMLElement
    swirlRef.classList.add("animate-swirl")
  }

  return (
    <section id="features" className="wrapper">
      <div className="inner">
        <h2>Advantages</h2>
        <div className="features">
          {features.map(feature => (
            <Feature key={feature.heading} {...feature} />
          ))}
        </div>
      </div>
      <Swirl2SVG />
    </section>
  )
}

export default Features
