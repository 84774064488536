import React, { useState } from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

import Intro from "../components/intro"
import "../scss/main.scss"
import Main from "../components/main"

const Home = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  // TODO REVERT TO FALSE
  const [isMain, setDisplay] = useState(false)

  const onClose = () => {
    setDisplay(true)
  }

  const renderDisplay = () => {
    if (isMain) {
      return <Main></Main>
    }
    return <Intro onClose={onClose} />
  }

  return (
    <React.Fragment>
      <SEO title={siteTitle} />
      {renderDisplay()}
    </React.Fragment>
  )
}

export default Home

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
