import React from "react"

const Feature = ({ heading, description, iconClass }) => (
  <section className={`feature-wrapper`}>
    <div className="overlay"></div>
    <span className={`icon major ${iconClass}`} />
    <h3>{heading}</h3>
    <p>{description}</p>
  </section>
)

export default Feature
