import React, { useState } from "react"

const ContactForm = ({ onSubmit }) => {
  const [state, setState] = useState({})
  const [status, setStatus] = useState({ loading: false, msg: null })
  const axios = require("axios")
  const endpoints = {
    contact: "https://cbms-application.herokuapp.com/bytelogs/api/v2/sendEmail",
  }

  const generateInquiryNumber = () => {
    let chars = "123456789ABCDEFHJKMNPRTWXT"
    let string_length = 8
    let randomstring = ""
    for (let i = 0; i < string_length; i++) {
      let rnum = Math.floor(Math.random() * chars.length)
      randomstring += chars.substring(rnum, rnum + 1)
    }
    return randomstring
  }

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const triggerSubmit = () => {
    let form = document.getElementById("contactForm") as HTMLElement
    form.submit()
  }

  const handleSubmit = e => {
    let msg = ""
    msg += state.message + "<br/><br/>"
    msg += "<b>Company Name:</b> " + state.companyName + "<br/>"
    msg += "<b>Authorized Representative Name:</b> " + state.repName + "<br/>"
    msg += "<b>Phone Number:</b> " + state.phone + "<br/>"
    msg += "<b>Email:</b> " + state.email + "<br/><br/>"
    let data2 = {
      mailOptions: {
        receiver: "rose@lgusuite.com",
        subject:
          "iCertify Merchant Application [" + generateInquiryNumber() + "]",
        message: msg,
      },
    }

    setStatus({
      loading: true,
    })
    axios.post(endpoints.contact, data2).then(response => {
      console.log(response)
      setState({
        message: "",
        companyName: "",
        repName: "",
        email: "",
        phone: "",
      })
      setStatus({
        loading: false,
        msg:
          response.data.type === "Passed"
            ? "Your message has been sent!"
            : "An error occurred. Please try again.",
      })
    })
    e.preventDefault()
  }

  return (
    <section className="contactFormSection">
      <form
        name="contact"
        id="contactForm"
        method="POST"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="contact" />
        <div className="fields">
          <div className="field">
            <label htmlFor="companyName">Name of Company</label>
            <input
              type="text"
              name="companyName"
              id="companyName"
              onChange={handleChange}
              required
            />
          </div>
          <div className="field">
            <label htmlFor="repName">Name of Authorized Representative</label>
            <input
              type="text"
              name="repName"
              id="repName"
              onChange={handleChange}
              required
            />
          </div>
          <div className="field">
            <label htmlFor="phone">Phone Number</label>
            <input
              type="text"
              name="phone"
              id="phone"
              onChange={handleChange}
              required
            />
          </div>
          <div className="field">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              name="email"
              id="email"
              onChange={handleChange}
              required
            />
          </div>
          <div className="field">
            <label htmlFor="message">Message</label>
            <textarea
              name="message"
              id="message"
              rows="5"
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="actions">
          <button
            type="submit"
            className="button submit"
            disabled={
              !state.companyName ||
              !state.email ||
              !state.repName ||
              !state.phone ||
              !state.message ||
              status.loading
            }
          >
            {status.loading ? "Sending..." : "Send Message"}
          </button>
        </div>
      </form>
      <p>{status.msg}</p>
    </section>
  )
}

export default ContactForm
