import React from "react"
import clientImage from "../images/About-Clientv2.jpg"

const About = () => (
  <section id="about" className="wrapper fullscreen">
    <div className="about-shell">
      <div className="image">
        <img src={clientImage} className="client-image" />
      </div>
      <div className="content">
        <div className="inner">
          <h2>About</h2>
          <div className="inner-content">
            <p>
              iCertify redefines the notarization landscape in the Philippines
              by being the the first digital notary that allows any person or
              business to get their documents legally notarized online.
            </p>
            <p>
              Every transaction is guaranteed to be easy, secure and most of
              all, TRUSTWORTHY.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default About
