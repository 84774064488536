import React from "react"
class Swirl2SVG extends React.Component {
  render() {
    return (
      <svg
        id="featuresSwirl"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1186.42 505.69"
      >
        <path
          className="swirl2"
          d="M52,337a186.55,186.55,0,0,1,71,2c40.36,9.17,67,30,92,50,55.26,44.19,48.53,62.21,93,91,22.45,14.54,56.12,36.34,99,31,47.91-6,77.8-42.14,86-53,29.36-38.86,13.66-61.45,38-120,8.62-20.73,30.6-73.6,83-107,16.5-10.52,81.73-52.1,146-21,47.85,23.15,81.79,80.12,71,132-9.29,44.66-48.12,68.27-74,84-9.17,5.57-108.71,63.82-209,14-53.5-26.58-79.24-70-89-87-9.61-16.72-40.87-73.09-28-147,2.92-16.78,13.83-74.38,65-119,57.26-49.93,124.93-48.73,147-48,87.9,2.92,145.48,56.75,165,75,28.42,26.57,45.62,57.38,80,119,49.86,89.36,45.52,104.41,85,170,46.85,77.84,78,129.56,132,139,57.23,10,109.58-32.78,133-55"
          transform="translate(-51.92 -38.3)"
        />
      </svg>
    )
  }
}

export default Swirl2SVG
