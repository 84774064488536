import React, { Component } from "react"

import Home from "./home"
import About from "./about"
import Features from "./features"
import Steps from "./steps"
import Contact from "./contact"

class Main extends Component {
  state = {
    scrollPos: false,
    screenSize: undefined,
  }

  componentDidMount() {
    this.setState({ screenSize: window.innerWidth })
    window.addEventListener("scroll", () => {
      this.setState({
        scrollPos: window.scrollY,
      })
    })
    window.addEventListener("resize", () => {
      this.setState({
        screenSize: window.innerWidth,
      })
    })
  }

  render() {
    return (
      <div id="main-wrapper">
        <Home />
        <About />
        <Features
          scrollPos={this.state.scrollPos}
          screenSize={this.state.screenSize}
        />
        <Steps
          scrollPos={this.state.scrollPos}
          screenSize={this.state.screenSize}
        />
        <Contact screenSize={this.state.screenSize} />
      </div>
    )
  }
}

export default Main
